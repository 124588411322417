<template>
  <div class="filter-exchange">
    <div class="filter-exchange__btn-block">
      <div class="filter-exchange__btn-left">
        <div class="filter-exchange__btn-left__label">Фильтр</div>
        <div class="filter-exchange__btn-left__reset-btn" @click="resetFilter">Сбросить</div>
      </div>
      <div @click="closeLeftSidebar" class="lg:tw-hidden">
        <zem-icon class="cursor-pointer" name="close" size="18" />
      </div>
    </div>
    <div class="filter-exchange__label mt-3">Дата добавления</div>
    <div class="filter-exchange__checkbox-block">
      <ZemCheckbox :value="filter.checkbox[0]" @input="inputCheckbox(0, $event)">Сегодня</ZemCheckbox>
      <ZemCheckbox :value="filter.checkbox[1]" @input="inputCheckbox(1, $event)">Последние 3 дня</ZemCheckbox>
      <ZemCheckbox :value="filter.checkbox[2]" @input="inputCheckbox(2, $event)">Последние 7 дней</ZemCheckbox>
      <ZemCheckbox :value="filter.checkbox[3]" @input="inputCheckbox(3, $event)">Последний месяц</ZemCheckbox>
      <div class="filter-exchange__period-block mt-3">
        <label class="filter-exchange__label">Период размещения заявки</label>
        <date-picker
          v-model="date"
          format="DD.MM.YYYY"
          locale="ru"
          placeholder="Выбрать период"
          range
          value-type="format"
          @change="changeRangeDate('date', $event, 'created_at')"
        >
          <template v-slot:footer>
            <div style="text-align: left" class="tw-grid tw-grid-cols-4 tw-gap-3 tw-w-full">
              <button
                v-for="shortcut in shortcuts"
                :key="shortcut"
                class="tw-text-xs tw-font-normal tw-text-[#000000ca] tw-p-1.5 tw-rounded tw-bg-[#F5F5F5] hover:tw-bg-[#0DB2B2] hover:tw-text-white tw-transition-all"
                @click="onShortcutClick('date', shortcut, 'created_at')"
              >
                {{ shortcut }}
              </button>
            </div>
          </template>
        </date-picker>
      </div>
      <div class="filter-exchange__period-block">
        <label class="filter-exchange__label">Период закрытия заявки</label>
        <date-picker
          v-model="dateArchive"
          format="DD.MM.YYYY"
          locale="ru"
          placeholder="Выбрать период"
          range
          value-type="format"
          @change="changeRangeDate('dateArchive', $event, 'partner_status_finish_at')"
        >
          <template v-slot:footer>
            <div style="text-align: left" class="tw-grid tw-grid-cols-4 tw-gap-3 tw-w-full">
              <button
                v-for="shortcut in shortcuts"
                :key="shortcut"
                class="tw-text-xs tw-font-normal tw-text-[#000000ca] tw-p-1.5 tw-rounded tw-bg-[#F5F5F5] hover:tw-bg-[#0DB2B2] hover:tw-text-white tw-transition-all"
                @click="onShortcutClick('dateArchive', shortcut, 'partner_status_finish_at')"
              >
                {{ shortcut }}
              </button>
            </div>
          </template>
        </date-picker>
      </div>
    </div>
    <div class="filter-exchange__dropdown">
      <div class="zem-dropdown mb-0">
        <label class="filter-exchange__label">Регионы</label>
        <v-select
          v-model="selectedRegions"
          :components="{OpenIndicator}"
          :options="$store.state.other['regions']"
          label="title"
          multiple
          placeholder="Выбрать"
          @input="changeRegions($event)"
        >
          <template #open-indicator="{attributes}">
            <img :src="require('@/assets/icons/dropdown-arrow.svg')" alt="" />
          </template>
        </v-select>
      </div>
      <div class="zem-dropdown mb-0">
        <label class="filter-exchange__label">Статус в кабинете</label>
        <v-select
          v-model="selectedStatuses"
          :components="{OpenIndicator}"
          :options="$store.state.exchange.statuses.map(el => ({value: el.id, title: el.title}))"
          label="title"
          multiple
          placeholder="Выбрать"
          @input="changeStatuses($event)"
        >
          <template #open-indicator="{attributes}">
            <img :src="require('@/assets/icons/dropdown-arrow.svg')" alt="" />
          </template>
        </v-select>
      </div>
      <div class="zem-dropdown mb-0">
        <label class="filter-exchange__label">Статус у партнера</label>
        <v-select
          v-model="selectedPartnersStatuses"
          :components="{OpenIndicator}"
          :options="$store.state.exchange.partnerStatuses.map(el => ({value: el.id, title: el.title}))"
          label="title"
          multiple
          placeholder="Выбрать"
          @input="changePartnersStatuses($event)"
        >
          <template #open-indicator="{attributes}">
            <img :src="require('@/assets/icons/dropdown-arrow.svg')" alt="" />
          </template>
        </v-select>
      </div>
      <div class="zem-dropdown mb-0" v-if="closeAcc([3])">
        <label class="filter-exchange__label">Разместил</label>
        <ZemDropdownList
          v-model="selectedCreatedBy"
          :components="{OpenIndicator}"
          :options="storeUsers ? storeUsers : []"
          class="my-0"
          placeholder="Выбрать"
          @input="changeCreatedBy($event)"
        >
          <template #open-indicator="{attributes}">
            <img :src="require('@/assets/icons/dropdown-arrow.svg')" alt="" />
          </template>
        </ZemDropdownList>
      </div>
    </div>
  </div>
</template>

<script>
import ZemCheckbox from './ui/Checkbox'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'
import moment from 'moment'
import ExchangeService from '@/services/exchange.service'
import ZemDropdownList from './ui/ZemDropdownList'
import 'vue-select/dist/vue-select.css'
import {closeAcc, errorHandler, selectDay} from '@/assets/scripts/scripts'
import ZemIcon from '@/components/ui/ZemIcon'
import UsersService from '@/services/users.service'
import shortcuts from '@/assets/scripts/shortcuts'

export default {
  name: 'FilterExchangeForm',

  components: {
    ZemCheckbox,
    DatePicker,
    ZemDropdownList,
    ZemIcon,
  },

  data() {
    return {
      selectedRegions: [],
      selectedStatuses: [],
      selectedPartnersStatuses: [],
      selectedCreatedBy: {
        value: '',
        title: '',
      },
      OpenIndicator: {
        render: createElement => createElement('span', ''),
      },
      filter: {
        checkbox: [false, false, false, false],
      },
      date: 'Выбрать период',
      dateCreate: 'Выбрать период',
      dateArchive: 'Выбрать период',
      exchangeStatus: {
        id: 'NxOpZowo9GmjKqdR',
        title: 'Активный',
      },
      partnersStatus: {
        title: 'Статус у партнера',
      },
      isDatePickerVisible: false,
      isDateCreatePickerVisible: false,
      isDateArchivePickerVisible: false,
      filtering: {},
      updateData: false,
      shortcuts,
    }
  },

  mounted() {
    if (this.$store.state.exchange.regions.length < 2) {
      Promise.all([
        ExchangeService.getFilterRegions(),
        ExchangeService.getFilterStatuses(),
        ExchangeService.getPartnerStatuses(),
        UsersService.getAllUsers(true),
      ]).then(() => {
        this.updateData = true
        this.changeRegions(this.storeRegions)
        // this.changeStatuses(this.storeStatuses)
        this.changePartnersStatuses([])
        // this.getFilter()
      })
    } else {
      this.changeRegions(this.storeRegions)
      // this.changeStatuses(this.storeStatuses)
      this.changePartnersStatuses([])
      // this.getFilter()
      this.updateData = true
    }
  },

  computed: {
    storeRegions() {
      return this.$store.state.exchange.regions.filter(item => ['Все регионы'].includes(item.title))
    },
    storeUsers() {
      return this.$store.state.users.users
    },
  },

  watch: {
    storeRegions() {
      this.selectedRegions = this.storeRegions
    },
    selectedRegions() {
      if (this.updateData) this.getFilter()
    },
    selectedStatuses() {
      if (this.updateData) this.getFilter()
    },
    selectedPartnersStatuses() {
      if (this.updateData) this.getFilter()
    },
    selectedCreatedBy() {
      if (this.updateData) this.getFilter()
    },
  },

  methods: {
    selectDay,
    closeAcc,
    changeRegions(event) {
      this.handlerSelected(event, 'selectedRegions', 'region_id')
    },
    changeStatuses(event) {
      this.handlerSelected(event, 'selectedStatuses', 'order_status_id')
    },
    changePartnersStatuses(event) {
      this.handlerSelected(event, 'selectedPartnersStatuses', 'order_partner_status_id')
    },
    changeCreatedBy(event) {
      if (event.value === null) {
        delete this.filtering['created_by']
      } else {
        this.filtering['created_by'] = event.value
      }
    },
    handlerSelected(e, data, search) {
      if (e.length > 0) {
        if (e[e.length - 1].value === 0) {
          this[data] = [e[e.length - 1]]
        } else if (e[0].value === 0) {
          this[data] = [e.find(item => item.value !== 0)]
        } else {
          this[data] = e
        }
      }
      this.filteringOrders(this[data], search)
    },
    filteringOrders(array, field) {
      let newArr = array.map(item => item.value)
      if (newArr.includes(0) || array.length === 0) {
        delete this.filtering[field]
      } else {
        this.filtering[field] = newArr
      }
    },
    inputCheckbox(num, event) {
      let a = this.filter.checkbox[num]

      this.filter.checkbox = [false, false, false, false]

      if (event) {
        this.filter.checkbox[num] = true
      }
      this.date = 'Выбрать период'

      delete this.filtering['created_at']
      delete this.filtering['status_active_at']
      delete this.filtering['partner_status_finish_at']
      if (!a) {
        if (num === 0) this.filtering['created_at'] = 'today'
        if (num === 1) this.filtering['created_at'] = 'last_three_days'
        if (num === 2) this.filtering['created_at'] = 'last_seven_days'
        if (num === 3) this.filtering['created_at'] = 'last_month'
      }

      this.getFilter()
    },
    changeRangeDate(date, event, name) {
      this.filter.checkbox = [false, false, false, false]
      if (event[0] === null && event[1] === null) {
        delete this.filtering[name]
      } else {
        this.filtering[name] =
          moment(this[date][0], 'DD.MM.YYYY').format('YYYY-MM-DD') +
          '|' +
          moment(this[date][1], 'DD.MM.YYYY').format('YYYY-MM-DD')
      }
      this.getFilter()
    },
    resetFilter() {
      this.$store.commit(`orders/getSearch`, '')
      this.updateData = false
      this.filter.checkbox = [false, false, false, false]
      this.date = null
      this.dateCreate = null
      this.dateArchive = null
      delete this.filtering['created_at']
      delete this.filtering['status_active_at']
      delete this.filtering['partner_status_finish_at']
      this.selectedRegions = []
      this.selectedStatuses = []
      this.selectedPartnersStatuses = []
      this.selectedCreatedBy = {
        value: '',
        title: '',
      }
      this.filtering = {}
      this.getFilter()
      setTimeout(() => {
        this.updateData = true
      }, 10)
    },
    createOrder() {
      ExchangeService.createOrder().catch(e => {
        errorHandler(e)
      })
    },
    onSelectRange(datePicker) {
      this[datePicker] = !this[datePicker]
    },
    closeLeftSidebar() {
      this.$store.commit('sidebars/changeLeftSidebar', false)
    },
    async getFilter(isLoadNew = false) {
      this.$store.commit('sidebars/changeRightSidebar', false)
      let data = ''
      let counter = 0
      let listCreatedName = ['today', 'last_three_days', 'last_seven_days', 'last_month']
      this.$store.commit('exchange/changeCurrentPage')

      for (let key in this.filtering) {
        data += key + ':' + this.filtering[key]
        if (!listCreatedName.includes(this.filtering[key])) {
          counter++
        }
        if (Object.keys(this.filtering).length !== counter) data += ';'
      }

      this.$store.commit('exchange/filterMain', data)
      this.$store.commit('exchange/getSearchCount', counter)

      await ExchangeService.getAllOrders(isLoadNew)
      this.$emit('get-order')
    },
    onShortcutClick(type, shortcut, name) {
      this[type] = selectDay(shortcut)
      this.changeRangeDate(type, this[type], name)
    },
  },
}
</script>

<style lang="scss">
.vdpr-datepicker__calendar-input-wrapper {
  //display: none;
}

.vdpr-datepicker {
  position: relative;
  left: 35px;
  top: -200px;
  z-index: 9;
}

.vdpr-datepicker__calendar-dialog--inline {
  position: absolute;
}

.filter-exchange {
  .mx-datepicker {
    width: 100%;
  }

  .mx-input-wrapper {
    .mx-input {
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      padding: 6px 6px 6px 8px;
      height: unset;
      border: 1px solid #f0f0f0;
      -webkit-box-shadow: unset;
      box-shadow: unset;
    }

    .mx-input:hover,
    .mx-input:focus {
      border-color: $color-accent;
    }

    .mx-input::-webkit-input-placeholder {
      color: #9ba6b3;
    }

    .mx-input::-moz-placeholder {
      color: #9ba6b3;
    }

    .mx-icon-calendar {
      color: #9ba6b3;
      font-size: 13px;
    }
  }
}
</style>

<style lang="scss" scoped>
.filter-exchange {
  display: block;
  padding: 32px 24px;
  min-width: 192px;
  overflow-y: auto;
  height: 100%;

  &__btn-block {
    display: flex;
    justify-content: space-between;
  }

  &__btn-left {
    display: flex;
    align-items: center;

    &__label {
      font-weight: 700;
      font-size: 12px;
      line-height: 13px;
      color: $color-mine-shaft;
    }

    &__reset-btn {
      font-weight: 600;
      font-size: 12px;
      line-height: 13px;
      color: $color-accent;
      text-align: right;
      flex: 1;
      margin-left: 8px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__label {
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: $color-gull-gray;
    text-align: left;
    margin-bottom: 3px;
  }

  &__checkbox-block {
    margin-top: 8px;

    label:not(:first-child) {
      margin-top: 8px;
    }
  }

  &__period-block {
    display: flex;
    margin-top: 8px;
    flex-direction: column;
  }

  &__btn-period {
    width: 100%;
    padding: 5px 6px 5px 8px;
    border: 1px solid $color-catskill-grey;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    cursor: pointer;
    background-color: $color-white;
    color: $color-gull-gray;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background: $color-iron;
      border: 1px solid $color-iron;
      //color: $color-oslo-gray;
      opacity: 0.8;
    }
  }

  &__icon-period {
    width: 16px;
    height: 16px;
    background-image: url('../assets/icons/calendar.svg');
  }
}

@media screen and (max-width: 1440px) {
  .filter-exchange {
    padding: 20px;
    min-width: 152px;
  }
}

@media screen and (max-width: 1024px) {
  .filter-exchange {
    &__dropdown,
    &__checkbox-block {
      width: 50%;
    }
  }
}

@media screen and (max-width: 450px) {
  .filter-exchange {
    &__dropdown,
    &__checkbox-block {
      width: 100%;
    }
  }
}
</style>
